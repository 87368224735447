.homeBgc {
    background-color: #fff;
}

.container {
    background-color: #fff;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
}

.h2 {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 32px;
}

.btnClassName :global .ant-btn {
    height: 20px;
    width: 20px;
    margin-right: 0;
    background: transparent !important;
    border: none !important;
}

.kikicommunityHotTitle {
    font-size: 32px !important;
    height: 45px !important;
    line-height: 45px !important;
}

.kikicommunityHotPerson {
    height: auto !important;
    background: #F9F9FA !important;
}

.kikicommunityHotTopic {
    height: 220px !important;
    height: fit-content !important;
    padding: 0 !important;
    margin-top: 0 !important;
    padding-top: 88px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}

.downloadArea {
    padding-top: 120px;
    background-color: #fff;
}

/* .activeContain{
    height: 263.5px;
}
.communityContain{
    height: 929px;
}
.hotTopicContain{
    height: 246px;
}
.marketContain{
    height: 493px;
}
.kikiMasterContain{
    height: 419px;
}
.dataContain{
    height: 334px;
}
.zonesContain{
    height: 157px;
}
.guideContain{
    height: 283px;
    margin-bottom: 100px;
}

.footerContain{
    height: 557px;
    padding-top: 4px;
} */

@media screen and (max-width: 1230px) {
    .container {
        margin-right: 15px;
        margin-left: 15px;
        width: calc(100% - 30px);
    }
}

@media screen and (max-width: 960px) {
    .communityContain {
        height: auto;
    }

    .hotTopicContain {
        height: auto;
    }

    .kikicommunityHotTitle,
    .kikiHotTitle {
        font-size: 20px !important;
        height: 28px !important;
        line-height: 28px !important;
    }

    .kikicommunityHotTopic {
        height: fit-content !important;
        padding-top: 36px !important;
    }

    .h2 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 15px;
    }

    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: 0;
        margin-left: 0;
        width: 100%;
    }

    .kikicommunityHotPerson {
        height: 215px;
        background: #fff;
        padding: 20px 0;
    }

    .kikicommunityHotPerson .hotPersonDataCont {
        display: flex;
        width: 100%;
        margin: 0 auto;
    }

    .kikicommunityHotPerson .hotPerson {
        display: flex;
        width: auto;
        overflow: auto;
        position: relative;
        margin-bottom: 0;
    }
}