.container {
  display: flex;
  flex-direction: row;
  padding-left: 8px;
  justify-content: center !important;
  align-items: center !important;
}

.tooltipsTitle {
  color: #fff;
}

.iconClassName {
  color: #fff;

}

.networkIcon {
  margin-left: 8px;
}

.stacking {
  margin-left: -3px;
}

.hoverColor {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center !important;
}