/* .container {

} */
.kikicommunityRight {
    max-height: 796px;
    margin-top: 56px;
    width: 360px;
    background: #F9F9FA;
    border-radius: 12px;
    overflow: hidden;
}

.kikicommunityRight .ghost {
  background-color: rgba(111, 64, 238, 0.1) !important;
  color: #6F40EE !important;
}

.kikicommunityRight .ghost:hover {
    background-image: linear-gradient(rgba(0,0,0,0.01), rgba(0,0,0,0.01));
}

.title {
    display: flex;
    /* align-items: center; */
}
.movieBtn {
    height: 28px;
}

.movieBtnContainer {
    margin-left: 12px;
    display: flex;
    align-items: center;
}

html[lang='en'] .movieBtnContainer {
    margin-top: 4px;
}

.content {
    display: flex;
}
.leftPart {
    position: relative;
    margin-right: 16px;
    flex: 1;
    overflow: hidden;
}
.rightPart {
    width: 350px;
    background-color: #F9F9FA;
    border-radius: 12px;
    margin-top: 56px;
}
.leftPart :global .ant-tabs-nav {
    background-color: #fff;
    padding-bottom: 24px;
}
.leftPart :global .ant-tabs-tabpane {
    padding: 24px 20px;
    border-radius: 12px;
    background-color: #F6F8FB;
    height: 796px;
    overflow: auto;
    -ms-overflow-style:none;
    scrollbar-width: none;
}

.leftPart :global .ant-tabs-tabpane::-webkit-scrollbar{
    display: none;
}

.moreBtn {
    position: absolute;
    width: 100%;
    height: 120px;
    bottom: 0;
    border-radius: 0 0 8px 8px;
    padding-top: 48px;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(180deg, rgba(249,249,250,0.00) 5%, #F9F9FA 90%);
}
.moreBtn :global .kiki-button {
    width: 280px;
    height: 48px;
    background-color: #F0EBFD;
    border: none;
    border-radius: 8px !important;
}
.moreBtn :global .kiki-button:hover {
  background-color: rgba(111, 64, 238, 0.15) !important;
}
.moreBtnContainer {
    background-color: #fff;
    width: 280px;
    height: 48px;
    border-radius: 8px;
}
.btnText {
    font-size: 16px;
    color: #6F40EE;
}
.leftPart:hover .moreBtn {
    opacity: 1;
}
.moreBtn {
    opacity: 0;
}

.btnClassName :global .ant-btn{
    height: 20px;
    width: 20px;
    margin-right: 0;
    background: transparent !important;
    border: none !important;
}
.kikicommunityHotTitle{
    font-size: 32px !important;
}
.kikicommunityHotPerson {
    height: 796px !important;
    background: #F6F8FB !important;
}
.mt40 {
    margin-top: 40px;
}
.leftPart [data-name="topic-tag-btn"] {
    background-color: #fff;
}

@media screen and (max-width: 960px){
    .mt40 {
        margin-top: 36px;
    }
    .kikicommunityRight {
        width: 100%;
        background: #fff;
        margin-top:  0;
    }
    .kikicommunityHotTitle,.kikiHotTitle{
        font-size: 20px !important;
    }
    .content {
        display: block;
    }
    .leftPart :global .ant-tabs-tabpane {
        padding: 0;
        height: auto;
    }
    .leftPart {
        margin-right: 0;
    }
    .rightPart {
        width: 100%;
      
    }
    .leftPart :global .ant-tabs-nav {
        padding-bottom: 12px;
    }
    .leftPart :global .ant-tabs-tabpane {
        background-color: #fff;
    }
    .moreBtn {
        opacity: 1;
        background-image: none;
    }
    .kikicommunityHotPerson {
        height: 230px !important;
        background: #fff !important;
        padding: 36px 0 !important;
    }
    .kikicommunityHotPerson .hotPersonDataCont {
        display:flex;
        width:100%;
        margin:  0 auto;
    }
    .kikicommunityHotPerson .hotPerson{
        display: flex;
        width: auto;
        overflow: auto;
        position: relative;
        margin-bottom: 0;
     }
}

@media screen and (min-width: 961px) {
    /* 点赞评论 */
    .leftPart :global [data-name="comment-action-group"] [data-active]:hover,
    .leftPart :global [data-name="comment-action-group"] [data-active="true"] {
        background-color: rgba(255, 255,255, 0.6);
    }

    .leftPart :global [data-name="comment-input-wrapper"] > span {
        background-color: rgba(255, 255,255, 0.6);
        border-color: rgba(255, 255,255, 0.6);
    }

    .leftPart :global [data-name="comment-input"],
    .leftPart :global [data-name="comment-input"]:focus {
        background-color: transparent;
    }
}