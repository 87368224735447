.list {
  justify-content: space-between;
}

.item {
  width: fit-content;
  flex: none;
}

.listMargin {
  margin-right: 17px;
}

.listLeft {
  justify-content: flex-start;
}

.mt56 {
  margin-bottom: 56px;
}

@media (max-width: 960px) {
  .mt56 {
    margin-bottom: 36px;
  }
}