.pageTop {
  width: 100%;
  height: 650px;
  position: relative;
  background-image: url(~@images/download_bg.webp);
  background-size: cover;
}

.contain {
  max-width: 1200px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  padding-top: 46px;
}

.intro {
  width: 607px;
  height: 532px;
  flex: 1;
  position: relative;
}

.download {
  flex: 1;
  width: 597px;
  height: 394px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 59px;
}

.line1 {
  width: auto;
  height: 62px;
  font-size: 40px;
  font-family: DINPro-Bold;
  line-height: 56px;
  font-weight: bold;
  color: #1F2126;
  background-image: url(~@images/line_bg.webp);
  background-size: 176px 25px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.line2 {
  font-size: 20px;
  line-height: 28px;
  color: #696F7F;
  margin-top: 15px;
}

.downloadInfo {
  display: flex;
  flex-direction: row;
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-right: 24px;
}

.btnWrapper .btn {
  position: relative;
  cursor: pointer;
  width: 184px;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
}

.btnWrapper .btn1 {
  background-image: url(~@images/download_apple.webp);
  width: 184px;
  height: 48px;
  background-size: cover;
}

.btnWrapper .btn:hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.btnWrapper .btn2 {
  background-image: url(~@images/download_google.webp);
  width: 184px;
  height: 48px;
  background-size: cover;
}

.scanImg {
  width: 120px;
  height: 120px;
  background: #FFFFFF;
  border: 1px solid #DDDEE2;
  border-radius: 8px;
}


@media screen and (max-width: 1250px) {

  .contain {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 960px) {

  .pageTop {
    height: 653px;
    background-image: url(~@images/download_bgH5.webp);
    background-size: cover;
    padding: 0;
  }

  .contain {
    flex-direction: column;
    align-items: center;
    padding: auto 30px;
  }

  .intro {
    width: 327.5px;
    height: 287px;
  }

  .download {
    width: 298.5px;
    height: 170px;
    margin-top: 60px;
    padding: 0;
  }

  .downloadInfo {
    margin-top: 55px;
  }

  .line1 {
    width: auto;
    height: 31px;
    font-size: 20px;
    line-height: 28px;
    background-size: 88px 12.5px;
  }

  .line2 {
    font-size: 10px;
    line-height: 14px;
    color: #696F7F;
    margin-top: 8px;
  }

  .btnWrapper {
    margin-right: 12px;
  }

  .btnWrapper .btn {
    width: 92px;
    height: 24px;
    border-radius: 4px;
    margin-bottom: 12px;
  }

  .btnWrapper .btn:hover {
    opacity: 0.12;
    background: #000000;
    border-radius: 8px;
  }

  .btnWrapper .btn1 {
    width: 92px;
    height: 24px;
  }

  .btnWrapper .btn2 {
    width: 92px;
    height: 24px;
  }

  .scanImg {
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }




}