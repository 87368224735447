.h2 {
  font-size: 32px;
  margin-bottom: 32px;
  color: #1F2126;
  margin-top: 88px;
  font-weight: 700;
}

@media (max-width: 960px) {
  .h2 {
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 36px;
  }
}