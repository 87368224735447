.movieBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 27px;
  background-color: rgba(111, 64, 238, 0.1);
  padding: 4px;
  padding-left: 11px;
  width: fit-content;
  cursor: pointer;
}

.text {
  font-size: 12px;
  color: #6F40EE;
  margin-right: 6px;
  font-family: PingFangSC-Medium !important;
}

html[lang="en"] .text {
  font-family: DINPro-Medium !important;
}
