.container :global .kiki-table .ant-table-thead > tr > th, 
.container :global .kiki-table .ant-table-tbody > tr > td {
    border-bottom: none;
}
.container :global .ant-table-content {
    margin-bottom: 0;
}
.container :global .kiki-table .ant-table-thead > tr > th {
    color: #696F7F;
}
.tabs :global .ant-tabs-nav {
    margin-bottom: 14px;
}
.moreBtn {
    font-size: 16px;
    color: #6F40EE;
    cursor: pointer;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}