.deletedPost {
  padding-top: 32px;
}
.scrollFooter {
  height: 104px;
  margin-top: 18px;
  border-top: 1px solid rgba(223,223,223,0.42)
}
.itemSeparator {

  height: 1px;
  margin-bottom:32px;
  margin-top: 18px;
  background-color: rgba(223,223,223,0.42);
  border-radius: 12px;
}
.loadingContent {
  margin-left: 0;
  margin-right: 0;
  background-color: #fff;
}
@media (max-width: 960px) {
  .itemSeparator {
    margin-bottom:20px;
    margin-top: 13px;
  }
}