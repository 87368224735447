.hidden {
  display: none;
}

.commentText {
  cursor: pointer;
}

.actionGroup {
  display: none;
}

.commentInput {
  margin-top: 0;
  padding-bottom: 0;
  height: fit-content;
}

.comment > .commentText {
  width: calc(100% - 140px);
  min-height: 40px;
}

.comment:hover > .actionGroup,
.comment > .visible {
  width: 140px;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 0;
}

.comment:hover > .actionGroup>div {
  width: 70px;
}
.indentation {
  margin-left: 46px;
  margin-top: 0px;
}
.moreComment,
.moreComment:hover {
  color: #6F40EE;
  font-size: 14px;
}

.indentation10 {
  margin-top: 10px;
}

@media screen and (max-width: 960px) {
  .comment:hover > .actionGroup,
  .comment > .visible {
    width: 140px;
    display: flex;
    position: unset;
    right: 0;
    top: 0;
    margin-top: 0;
  }
  .comment > .commentText {
    width: 100%;
    min-height: 40px;
  }
}