.listItem {
    height: 192px;
    width: 286px;
    border-radius: 12px;
    flex-shrink: 1;
    cursor: pointer;
    position: relative;
    margin-right: 14px;
    margin-bottom: 40px;
    transition: margin-top 0.5s ease-in-out;

    &:hover {
        margin-top: -16px;
    }
}

.brief {
    border-radius: 12px;
    position: relative;
    bottom: 0;
    z-index: 1;
    height: 160px;
    width: 100%;
    padding: 0 24px 20px;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: #F9F9FA;
    box-shadow: 0 2px 4px #EBE9F1;
}

.projectImage {
    width: 96px;
    height: 96px;
    border-radius: 8px;
    overflow: hidden;
    top: -31px;
    position: absolute;
}

.projectTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.projectName {
    color: #000000;
    font-size: 20px;
    line-height: 28px;
    font-family: DINPro-Bold, PingFangSC-Medium !important;
    margin-right: 8px;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.keyWords {
    color: #8E939F;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 240px;
    white-space: nowrap;
    word-wrap: break-word;
    word-break: break-all;
}

@media (max-width: 960px) {
    .listItem {
        height: 192px;
        min-width: 288px;
        cursor: pointer;
        position: relative;
        margin-right: 10px;
        margin-bottom: 0;
        border-radius: 8px;

        &:hover {
            margin-top: 0;
        }
    }

    .projectImage {
        width: 96px;
        height: 96px;
    }

    .projectName {
        font-size: 20px;
        line-height: 28px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 380px !important;
        white-space: nowrap;
        word-wrap: break-word;
        word-break: break-all;
    }

    .brief {
        border-radius: 8px;
        padding: 0 24px 20px 24px;
        width: 100%;
        box-shadow: none
    }

    .keyWords {
        width: 95%;
        font-size: 16px;
        line-height: 24px;
    }

}