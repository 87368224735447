.container {}

.itemContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 64px;
}

.moreBtn {
    font-size: 16px;
    color: #6F40EE;
    cursor: pointer;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}


@media (max-width: 960px) {
    .itemContainer {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        overflow-y: auto;
        padding-top: 60px;
    }

    .itemContainer::-webkit-scrollbar {
        display: none;
    }

    .moreBtn {
        font-size: 12px;
    }
}