.headerItemTextContainer {
  flex: 1;
  margin-top: 16px;
}
.headerItemText {
  color: #1f2126;
  font-size: 16px;
  line-height: 28px;
}
.headerContainer {
  position: relative;
  margin-bottom: 0;
}
.headerMain {
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.headerMainLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.infoContainer {
  margin-left: 12px;
}
.level {
  font-size: 14px;
  line-height: 18px;
  color: #1f2126;
  font-weight: 500;
}
.timeContainer {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  color: #696f7f;
  align-items: center;
}
.titleContainer {
  margin-top: 16px;
}
.titleText {
  color: #000;
  font-size: 24px;
  line-height: 24px;
  font-family: DINPro-Bold;
}
.nullTitle {
  height: 10px;
}
.showTextMore {
  color: rgb(122, 52, 247);
  font-size: 14px;
  line-height: 22px;
}

.followBtn {
  font-size: 12px;
}
.time {
  font-size: 12px;
  color: #696f7f;
  line-height: 14px;
  font-family: DINPro-Regular !important;
}
.intro {
  margin-left: 8px;
  font-family: 'DINPro-Regular' !important;
  font-size: 12px;
  color: #696f7f;
  line-height: 14px;
  word-break: break-all;
  flex: 1;
  width: 310px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media (max-width: 960px) {
  .intro {
    width: 175px;
    font-size: 10px;
  }
  .titleText {
    font-size: 17px;
    line-height: 23px;
  }
  .headerItemText {
    font-size: 15px;
    line-height: 23px;
  }
}
