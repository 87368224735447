.pageTop{
  /* background: #F9FAFE; */
  width: 100%;
  height: 576px;
  position: relative;
  background-image: url(~@images/bg.webp);
  background-size: contain;
  background-size: cover;
}

.img{
  width: 24.17vw !important;
  height: 27.91vw !important;
}
.contain{
  display: flex; 
  /* height: 616px; */
  max-width: 1200px;
  /* background: #F9FAFE; */
  display: flex;
  justify-content: space-between;
  /* padding-top: 52px; */
  /* padding-top: 28px; */
  margin: 0 auto;
  align-items: center;
  padding-top: 46px;
}

.left{
  /* margin-top: 120px; */
}

.line1{
  font-size: 46px;
  color: #1F2126;
  /* margin-top: 24px; */
}
.line2{
  max-width: 584px;
  font-size: 16px;
  color: #696F7F;
  /* margin-right: 25px; */
  margin-top: 5px;
}

.bottom{
  /* position: absolute; */
  /* bottom: 0; */
  /* height: 106px; */
  width: 100%;
  background-repeat: no-repeat;
  background-image: url(~@images/quick_start_background.webp);
  background-size: 100% 100%;
}
.list{
  max-width: 1200px;
  display: flex;
  padding: 9px 0;
  margin: 0 auto;
  justify-content: space-between;
  /* background: rgba(249, 250, 254, 0.69); */
  /* backdrop-filter: blur(20px) */
}
.listItem{
  max-width: 282px;
  height: 88px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 21px 12px; */
}

.listItemTop{
  font-weight: 500;
  font-size: 28px;
  text-align: left;
}

.listItem:last-child{
  text-align: left;
  /* padding-right: 45px; */
  justify-content: flex-end;
}

.listItem:first-child{
  text-align: left;
  justify-content: flex-start;
}
.listItem:last-child .listItemTop{
  text-align: left;
}


.listItemBottom{
  font-size: 14px;
  color: #696F7F;
}

.title{
  font-size: 30px;
  color: #1F2126;
  margin-bottom: 24px;
}
.description{
  font-size: 16px;
  color: #696F7F;
}

.left :global .ant-btn.kiki-button.ant-btn-primary{
  margin-top: 35px;
  width: 120px;
  height: 48px;
  font-size: 14px;
  border-radius: 8px !important;
}

.quick{
  width: 89px;
  height: 28px;
  background: rgba(111, 64, 238, 0.05);
  border-radius: 27px;
  color: #6F40EE;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.quick :global .anticon{
  font-size: 16px;
  margin-left: 5px;
}

.h5Contain{
  display: none;
}

@media screen and (max-width: 1250px){
    .contain,.list{
      padding-left: 15px;
      padding-right: 15px;
    }
    .listItem:last-child{
      padding-right: 0px;
    }
}

@media screen and (max-width: 960px) {
  .right{
    display: none;
  }
  .pageTop{
    height: auto;
    background-image: none;
    padding: 29.5px 15px 0;
    background: white;
  }
  .contain{
  /* background: #F9FAFE; */
  display: flex;
  justify-content: space-between;
  /* margin: 0 auto; */
  align-items: center;
  padding: 0;
  }
  .left{
      width: 100%;
    }
  .line1{
      margin-top: 12px;
      font-size: 30px;
  }
  .h5Contain{
    background: white;
    padding: 29.5px 15px 0;

  }
  .list{
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    background: white;
    flex-wrap: wrap;
    margin-top: 15px;
 }
 .listItem{
   width: 50%;
   height: 48.5px;
   flex-grow: 0;
   margin-top: 21px;
 }
 .listItem .listItemTop{
   font-size: 18px;
 }
 .listItem .listItemBottom{
   font-size: 12px;
 }
 .list>div,.list>div:last-child{
   text-align: left;
   justify-content: flex-start;
   text-align: left;
 }
 .list>div:last-child .listItemTop{
   text-align: left;
 }
 .h5Contain :global .ant-btn.kiki-button.ant-btn-primary{
  margin-top: 84px;
  width: 180px;
  height: 48px;
  font-size: 14px;
  border-radius: 8px !important;
}
}

.emptyStyle{
  width: 100%;
  height: 50vh;
  background: white;
}