.container {
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  margin-bottom: 4px;
}

.actionGroup {
  flex: 1;
  position: unset;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  max-width: 426px;
}

.item {
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 0 !important;
}

.action {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.item,
.action {
  width: fit-content;
  height: 40px;
}

.action > span:first-child {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.action > span:first-child > svg {
  margin: 0;
}

@media (max-width: 960px) {
  .actionGroup {
    height: 24px;
  }
  .item,
.action {
  height: 24px;
}
  .action > span:first-child {
    width: 24px;
    height: 24px;
  }
  .container {
    width: 200px;
  }
}
