.text {
    color: #434B5F;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    word-break: break-all;
    white-space: pre-line;
    font-family: DINPro-Regular !important;

    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
.text :global mark {
    /* background-color: #fff; */
    background-color: transparent;
}
.text :global a {
    color: #434B5F;
}
.text :global div,
.text :global p,
.text :global a,
.text :global mark {
    font-family: DINPro-Regular !important;
}

/* 帖子被删除 */
.deletedPost {
    /* height: 166px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.desc {
    margin-bottom: 12px;
    margin-top: -8px;
}

.delBtn {
    height: 40px !important;
    padding: 0 16px !important;
    font-weight: 500;
    color: rgba(0,0,0,0.8) !important;
}
.feedImage {
    border-radius: 4px;
}
.itemThreeContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    width:fit-content;

}
.imgCount {
    position: absolute;
    background-color: rgba(0,0,0,0.4);
    bottom: 8px;
    right: 8px;
    height: 16px;
    padding: 0 6px;
    border-radius: 10px;
}
.imgCountNum {
    color: #FFF;
     font-size: 12px
}
.imgCintainer {
    margin-top: 14px;
}
.videoCintainer {
    margin-top: 10px;
}
.threeImgCintainer {
    margin-top: 20px;
}

